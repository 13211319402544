import React from 'react';
import { RichText } from 'prismic-reactjs';

// styles
import { Container, NormalText } from '@styles';
import {
  CoachesSection,
  Row,
  Card,
  CardMedia,
  CardContent,
  ImageCol,
  TextCol,
  Title,
} from '../coaches/styles';

const Results = ({ data }) => {
  return (
    <>
      {data.map((reference, i) => (
        <CoachesSection key={i}>
          <Container normal>
            <Row>
              <ImageCol>
                <Card>
                  <CardMedia>
                    <img
                      src={reference.image && reference.image.url}
                      alt={reference.image && reference.image.alt}
                    />
                  </CardMedia>
                  <CardContent>
                    {RichText.render(reference.name)}
                    <p>{reference.position}</p>
                  </CardContent>
                </Card>
              </ImageCol>
              <TextCol>
                <Title>{RichText.asText(reference.name)}</Title>
                <RichText render={reference.text} Component={NormalText} />
              </TextCol>
            </Row>
          </Container>
        </CoachesSection>
      ))}
    </>
  );
};

export default Results;
